














































































import { IDelivery } from '@/interfaces/IDelivery.interface';
import { DeliveriesMixin } from '@/mixins';
import { Component, Mixins } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { DeliveriesStore } from '../store/modules/deliveries.store';

@Component({
  computed: {
    ...mapGetters('Deliveries', ['unassignedDeliveries', 'onRouteDeliveries']),
  },
})
export default class DashboardView extends Mixins(DeliveriesMixin) {
  private editingDelivery = false;
  private delivery: IDelivery | null = null;
  private loading = true;
  private autoRefresh = false;
  private autoRefreshInterval;
  private rerenderCards = false;

  // LifeCycle
  created() {
    this.reloadDeliveries();
    this.toggleAutoRefresh();
  }

  beforeDestroy() {
    clearInterval(this.autoRefreshInterval);
  }

  toggleAutoRefresh() {
    this.autoRefresh = !this.autoRefresh;

    if (this.autoRefresh) {
      this.autoRefreshInterval = setInterval(() => {
        this.reloadDeliveries();
      }, 60000);
    } else {
      clearInterval(this.autoRefreshInterval);
    }
  }

  reloadDeliveries() {
    this.getDeliveries()
      .then(({ edges }) => {
        const deliveries = edges.map(e => e.node);
        DeliveriesStore.SET_DELIVERIES(deliveries);
        this.rerenderCards = !this.rerenderCards;
        this.loading = false;
      })
      .catch(e => {
        this.loading = false;
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error',
          text: 'Unable to get deliveries',
        });
      });
  }

  onEditDelivery(delivery: IDelivery) {
    this.delivery = delivery;
    this.editingDelivery = true;
  }

  onDeliverySaved(delivery: IDelivery) {
    this.editingDelivery = false;
    this.reloadDeliveries();
  }
}
